;"use strict";

var NS = {
    header: function(){},
    main: function (){},
    footer: function () {},
    check_to_top: function(){
        if(jQuery(document).scrollTop() >= 100){
            jQuery("#to_top").show(400);
        }else{
            jQuery("#to_top").hide();
        }
    },
    to_top: function(){
        if(jQuery("a[href='#top']")){
            jQuery("a[href='#top']").on("click", function() {
                jQuery("html, body").animate({ scrollTop: 0 }, "slow");
                return false;
            });
        }
    },
    init: function () {
        this.header();
        this.main();
        this.footer();
        this.check_to_top();
        this.to_top();
    }
};

$(window).on("load", function () {

    NS.init();

    $('.js_select2').select2({
        minimumResultsForSearch: -1
    });


    $('.btn_menu').on('click', function(){
        $(this).toggleClass('open');
        $("#menu_wrap").toggleClass('show');
        $("body").toggleClass('ov_h');
        return false;
    });

    if($('.themes_list_wrap').length && $(".program_list").length){
        $(".program_list").find(" > li:last").addClass("last");
        $('.themes_list_wrap').find("span.down").on("click", function () {
            $(this).toggleClass("top");
            $(this).closest(".cont").prev().toggleClass("active");
            $(this).find(" + .hidden_list ").slideToggle();
        });
    }

    if($(".read_more").length && $('.services_list').length){

        var $v = '<a href="#" class="a_read_more">свернуть</a>';
        $('.services_list .hidden_more').append($v);

        $(".a_read_more").on("click", function () {
            $(this).parent().slideToggle();
            $(this).parent().prev('.read_more').toggleClass("hidden");
            $(this).parent().prev('.read_more').toggleClass("active");
            return false;
        });

        $(".read_more").on("click", function () {
            if($(this).closest(".services_list").length){
                $(this).toggleClass("hidden");
            }
            $(this).find(" + .hidden_more").slideToggle();
            $(this).toggleClass("active");
            return false;
        });
    }

    if($(".read_more").length && $(".read_more").closest(".section_details").length){
        $(".read_more").on("click", function () {
            $(".read_more").closest(".section_details").find(".container").find("> p").toggle();
            return false;
        });
    }

    if($(".tabs")){
        $(".tabs").each(function () {
            $(this).find(" > li:first");
        });

        $(".tabs").find(".tabs_item").on("click", function (){
            $(".tabs").find(".tabs_item").removeClass("active");
            $(this).addClass("active");

            $(".content_tab").hide();
            $("#" + $(this).data('id')).show();
        });

        $(".content_tab ul li .title").on("click", function () {
            $(this).find(" + .content").slideToggle();
            $(this).toggleClass("active");
        });

    }

    if($(".section_details .container > p").length){
        $(".section_details .container > p").each(function (i, elem) {
            //console.log(i);
        });
    }

    var $owl = $(".owl-carousel");
    if($owl){
        $owl.owlCarousel({
            nav: true,
            items: 3,
            loop: false,
            responsiveClass:true,
            navText: ["<span class='arrow_l'><i class='icon-angle-left'></i></span>","<span class='arrow_r'><i class='icon-angle-right'></i></span>"],
            responsive:{
                0:{
                    items: 1,
                    loop: false,
                    margin: 0,
                    nav:true
                },
                768:{
                    items: 2,
                    loop: false,
                    margin: 75,
                    nav:true
                },
                992:{
                    items: 3,
                    margin: 150,
                    loop: false,
                    nav:true
                }
            }
        });
    }


    var $steps_list = $(".steps_list"),
        $steps_answers = '<div class="steps_answers"></div>';
    if($steps_list.length){
        $steps_list.parent().append($steps_answers);
        $(".steps_answers").html($steps_list.find(" > li:first .hidden_content").html());
        $steps_list.find("li").each(function (i, elem) {
            $(this).on("click", function () {
                $(".steps_answers").html();
                var $h_c = $(this).find(".hidden_content").html();
                $(".steps_answers").html($h_c);
                if($(window).width() <= 767){
                    console.log($(".steps_answers").offset().top);
                    $("html, body").animate({ scrollTop: $(".steps_answers").offset().top }, "slow");
                }
                return false;
            });
        });
    }

    //$(".nano").nanoScroller();
    //jQuery('.scrollbar-macosx').scrollbar();

    $(window).on("resize", function () {});

    $(document).on("scroll", function () {
        NS.check_to_top();
    });

});